<template>
  <b-row class="align-items-end">
    <b-col
      sm="12"
      md="8"
      class="mb-1 mb-sm-0">
      <SearchTextInput
        v-model="search"
        label="Search email"
        placeholder="Email"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="4"
      class="mb-1 mb-sm-0">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="w-100"
        variant="primary"
        @click="fetchFilter()">
        <feather-icon
          icon="SearchIcon"
          class="mr-50 mr-md-0 mr-lg-0 mr-xl-50" />
        <span class="align-middle">Search</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'

export default {
  components: {
    SearchTextInput
  },
  props: {
    statusFilter: {
      type: String,
      default: null
    },
    filter: {
      type: Object,
      default: () => (
        {
          search: ''
        }
      )
    }
  },
  computed: {
    search: {
      get () {
        return this.filter.search
      },
      set (val) {
        this.$emit('update:search-favorite-email', val)
      }
    }
  },
  methods: {
    fetchFilter () {
      this.$emit('fetch-filter')
    }
  }
}
</script>

<style>

</style>
